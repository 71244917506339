<template>
    <v-row :justify="`${$vuetify.breakpoint.mobile ? 'start' : 'center'}`" :class="`ma-0 ${$vuetify.breakpoint.mobile ?  'mobileOrientation' : ''}`">
        <v-col cols="2" v-if="!$vuetify.breakpoint.mobile"></v-col>
        <v-col cols="3" :class="`mt-6 ${$vuetify.breakpoint.mobile ? 'ml-5' : ''}`">
            <v-select
                :disabled="streamPlaying || streamLoading"
                density="compact"
                v-model="audioSource"
                :items="audioSources"
                label="Audio Source"
                class="pa-0 ma-0"
                item-text="label"
                item-value="device"
            ></v-select>
        </v-col>
        <v-col cols="3" class="mt-6">
            <v-select
                :disabled="streamPlaying || streamLoading"
                density="compact"
                v-model="videoSource"
                :items="videoSources"
                label="Video Source"
                class="pa-0 ma-0"
                item-text="label"
                item-value="device"
                ></v-select>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.mobile" cols="4" align-self="center">
            <v-btn style="width: 12em;" v-if="streamLoading">
                <v-progress-circular
                    style="height: 1.5em"
                    indeterminate
                    color="secondary"/>
            </v-btn>
            <v-btn v-else-if="streamPlaying" style="width: 12em" color="red" @click="stopStream()">{{ $t('streaming.stop_stream') }}</v-btn>
            <v-btn v-else style="width: 12em" color="primary" @click="startStream()">{{ $t('streaming.start_stream') }}</v-btn>
            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <div style="display: inline-block; vertical-align: top;" class="ml-3 pa-0" v-on="on">
                    <v-btn
                    class="ml-0"
                    :v-tooltip="$t('streaming.open_analysis')"
                    :disabled="!streamAvailable"
                    @click="openAnalysis"
                    >
                        <v-progress-circular v-if="checkingStreamAvailability && !streamAvailable || streamLoading" style="height: 1.5em" indeterminate></v-progress-circular>
                        <v-icon v-else>mdi-open-in-new</v-icon>
                    </v-btn>
                </div>
                </template>
                {{ $t('streaming.open_analysis') }}
            </v-tooltip>
        </v-col>
        <v-col v-else cols="2" align-self="center" class="mobileOrientation">
            <v-btn style="width: 8em" v-if="streamLoading">
                <v-progress-circular
                    style="height: 1.5em"
                    indeterminate
                    color="secondary"/>
            </v-btn>
            <v-btn v-else-if="streamPlaying" style="width: 8em" color="red" @click="stopStream()">{{ $t('streaming.stop_stream_mobile') }}</v-btn>
            <v-btn v-else style="width: 8em" color="primary" @click="startStream()">{{ $t('streaming.start_stream_mobile') }}</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    props: ['canvas'],
    methods: {
        ...mapActions('streaming', [
            'startStream',
            'stopStream',
            'setSelectedAudioSource',
            'setSelectedVideoSource',
            'getStream'
        ]),

        async openAnalysis() {
            const stream = await this.getStream()
            const streamId = stream.data.stream.streamId;
            const newWindowUrl = `${window.location.origin}/#/videos/${streamId}`
            window.open(newWindowUrl, '_blank');
        }
    },
    computed: {
        ...mapGetters('streaming', [
            'streamPlaying',
            'audioSources',
            'videoSources',
            'selectedVideoSource',
            'selectedAudioSource',
            'streamLoading',
            'streamAvailable',
            'checkingStreamAvailability'
        ]),
        audioSource: {
            get() {
                return this.selectedAudioSource;
            },
            set(value) {
                this.setSelectedAudioSource(value);
            },
        },
        videoSource: {
            get() {
            return this.selectedVideoSource;
            },
            set(value) {
                this.setSelectedVideoSource(value);
            },
        },
    }   
}
</script>

<style scoped>
@media (orientation: portrait) {
.mobileOrientation {
  justify-content: start;
}
}
@media (orientation: landscape) {
.mobileOrientation {
  justify-content: center;
}
}
</style>